<template>
  <tablebox v-loading="state.loading" :element-loading-text="config.loading.text"
    :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
    <div class="padding-20 wtm_height bg-fff autobox">
      <div class="dtitle fs-20">默认角色</div>
      <div class="dp-f fw-w mt-20">
        <div class="permission padding-20 mb-20" v-for="(items, ind) in state.roleData" :key="ind" style="padding-bottom: 10px;">
          <div class="dtitle fs-16">{{ items.roleName }}</div>
          <div class="dp-f">
            <div class="roleMembers mt-10">角色描述：</div>
            <div class="mt-10 mb-6">
              {{ items.roleDesc }}
            </div>
          </div>
          <div class="dp-f">
            <div class="roleMembers mt-5">角色成员：</div>
            <div class="mt-5 dp-f fw-w">
              <div class="dp-f ai-c pt-5 pb-5 pl-8 pr-8 membersIntroduc mr-8 mb-10"
                v-for="(item, index) in items.userList">
                <!-- <img src="@/assets/img/role/peopleIcon.png" alt="" class="w-34 h-34" /> -->
                <div class="pb-1">{{ item.chnname }}</div>
                <img src="@/assets/img/role/delcon.png" class="ml-4 cu-p pt-1 w-14 h-14" alt="" @click="updateUserAndRole(items.roleId, item.userId,1)"  />
              </div>
              <addressbook ref="addressbookRef" v-model:roleId ="items.roleId" @choose="updateUserAndRole(items.roleId, $event.userId,$event.role?1:2,ind)" v-if="ind!=0"></addressbook>
            </div>
            <!-- 通讯录弹出框-->
          </div>
        </div>
      </div>
    </div>
  </tablebox>
</template>
<script setup>
import { reactive, ref, unref } from "vue";
import { httpToken } from "@/utils/request";
import {debounce} from "@/utils/util.js"
import addressbook from "./components/rolesAndPermissions/addressbook.vue"
import qs from "qs";
const addressbookRef=ref()
const state = reactive({
  loding: false,//页面loding
  roleData: [],//角色列表
});
// 通过用户id和角色id删除或增加角色信息 ,type:1 为删除 2为添加
const updateUserAndRole=debounce(function(roleId,userId,type,ind){
  console.log('roleId,userId,type,ind',roleId,userId,type,ind)
  httpToken({
    method: "post",
    url: '/admin/sys-role/updateUserAndRole',
    data: qs.stringify({
      userId:userId,
      roleId:roleId,
      type:type,
    }),
  }).then((res) => {
    getAllRolesData()
    unref(addressbookRef)[ind].selectAllUserByRoleId(true)
  })
},200)
// 获取所有角色数据
const getAllRolesData=() => {
  state.loading=true;
  let json = [
    { column: "sort", type: 'orderByAsc' }
  ];
  httpToken({
    method: "post",
    url: '/admin/sys-role/all',
    data: qs.stringify({
      paramData:JSON.stringify(json)
    }),
  }).then((res) => {
    // console.log('获取所有角色数据',res)
    state.roleData=res.data
    state.loading=false;
  }).catch(()=>{
    state.loading=false;
  })
}
getAllRolesData()
</script>
<style lang="scss" scoped>
@import "@/styles/general/element.scss"; //element 样式重置

.padding-20 {
  padding: 20px;
}

.dtitle {
  color: #333333;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}


.permission {
  width: 32%;
  margin-right: 2%;
  background: #f1f1f1;
}


@media screen and (min-width: 1530px) {
  :nth-child(3n).permission {
    margin-right: 0;
  }
}

@media screen and (max-width: 1530px) {
  .permission {
    width: 48%;
    margin-right: 4%;
  }

  :nth-child(2n).permission {
    margin-right: 0;
  }
}

.roleMembers {
  flex-shrink: 0;
  position: relative;
  width: 80px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.membersIntroduc {
  background: #e0e0e0;
  border-radius: 3px;
}

.fs-0 {
  flex-shrink: 0;
}
</style>
